















































import Vue from 'vue'
export default Vue.extend({
  name: 'recharge-card',
  props: ['recharge'],
  data() {
    return {
      expanded: false,
      ticketId: null,
      ticketFirstname: null,
      ticketOperator: null,
      ticketCreatedAt: null,
      ticketAmount: null,
    }
  },
  methods: {
    printReceipt() {
      let printContent = document.getElementById('ticket' + this.recharge.id);
      let WinPrint = window.open('', '', 'width=900,height=650');
      WinPrint?.document.write(printContent!.innerHTML);
      WinPrint?.print();
      WinPrint?.closed;
    }
  },
})
